<template>
  <RevSkeleton
    v-for="line in commentLines"
    :key="line"
    class="mb-4 last:mb-0"
    shape="line"
    :width="`${Math.ceil(Math.random() * 100)}%`"
  />
</template>

<script lang="ts" setup>
import { RevSkeleton } from '@ds/components/Skeleton'

const NB_LINES_COMMENT = 3
const commentLines: Array<number> = Array.from({ length: NB_LINES_COMMENT })
</script>
