import { MarketCountryCode } from '@backmarket/http-api'

export const COUNTRIES_WITH_REVIEWS_LEGAL_MODAL = [MarketCountryCode.FR]

export const MAX_RATING = 5

export const MAX_COMMENT_LENGTH = 1200

export const TRACKING_ZONE = 'reviews'

export const REVIEWS_LIST_DEFAULT_LENGTH = 10

export const MAX_REVIEWS_DISPLAYED_IN_LIST = 7
